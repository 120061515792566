import React from 'react';

import { makeStyles, Theme, AppBar, Toolbar } from '@material-ui/core';

import ProductBadge from './ProductBadge';
import { ProductBarProps } from './ProductBar.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(4),
    display: 'flex'
  },
  toolBar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1)
  }
}));

const ProductBar = ({ title, href, onClick, children }: ProductBarProps) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <ProductBadge title={title} href={href} onClick={onClick} />
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default ProductBar;
