import React from 'react';

import { Divider, Grid } from '@material-ui/core';

import { LayoutProps } from './Layout.proptypes';
import useStyles from './Layout.styles';

const Layout = ({ aside, header, main, actionBar }: LayoutProps) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.root} direction="column" wrap="nowrap" spacing={0}>
      {header ? (
        <Grid item xs={12} className={styles.header} component="header">
          {header}
        </Grid>
      ) : (
        ''
      )}
      {actionBar ? (
        <Grid item xs={12} className={styles.header}>
          {actionBar}
        </Grid>
      ) : (
        ''
      )}
      <Divider />
      <Grid item container className={styles.container} wrap="nowrap" spacing={0}>
        {aside ? (
          <Grid item xs={3} lg={2} component="aside">
            {aside}
          </Grid>
        ) : (
          ''
        )}
        <Divider flexItem orientation="vertical" />
        {main ? (
          <Grid item xs className={styles.container} component="main">
            {main}
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default Layout;
