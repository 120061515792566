import React, { useState } from 'react';

import { makeStyles, Theme, Avatar, Menu, IconButton } from '@material-ui/core';

import { UserMenuProps } from './UserMenu.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginBottom: theme.spacing(1)
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#000',
    width: 30,
    height: 30,
    paddingTop: 3
  }
}));

const UserMenu = ({ avatarText, children }: UserMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuitems = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement, {
      onClick: handleCloseMenu
    });
  });

  return (
    <>
      <IconButton className={classes.icon} onClick={handleOpenMenu}>
        <Avatar className={classes.avatar}>{avatarText}</Avatar>
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleCloseMenu}
      >
        {menuitems}
      </Menu>
    </>
  );
};

export default UserMenu;
