import React, { useContext, useEffect, useRef } from 'react';

import { Box, Divider, IconButton, Portal, Slide, Typography, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { SlideOutContainerProps, SlideOutDrawerProps } from './SlideOut.proptype';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
    width: (props?: Partial<SlideOutDrawerProps>) => props?.fixedWidth ?? '50%',
    [theme.breakpoints.up('lg')]: {
      width: (props?: Partial<SlideOutDrawerProps>) => props?.fixedWidth ?? '30%'
    }
  },
  drawerContent: {
    overflow: 'auto'
  },
  floating: {
    position: 'absolute',
    zIndex: 10,
    right: 0,
    bottom: 0,
    top: 0
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 2),

    '& h3': {
      flexGrow: 1
    }
  },
  subtitle: {
    padding: theme.spacing(1.5, 2),
    fontWeight: theme.typography.fontWeightBold
  }
}));

const context = React.createContext({} as React.MutableRefObject<undefined>);

const Container = ({ children }: SlideOutContainerProps) => {
  const classes = useStyles();
  const container = useRef();

  return (
    <context.Provider value={container}>
      <Box {...{ ref: container }} className={classes.container}>
        <Box flexGrow={1} overflow="auto" component="main">
          {children}
        </Box>
      </Box>
    </context.Provider>
  );
};

const Drawer = ({
  title,
  subtitle,
  titleHeight,
  isOpen,
  isFloating,
  isDividerHidden,
  children,
  onClose,
  fixedWidth
}: SlideOutDrawerProps) => {
  const classes = useStyles({ fixedWidth });
  const container = useContext(context);

  useEffect(() => {
    // some MUI components like tabs are listening to window resize, so they adapt their width
    window.dispatchEvent(new Event('resize'));

    return () => {
      window.dispatchEvent(new Event('resize'));
    };
  }, [isOpen]);

  return (
    <Portal container={container.current}>
      <Slide mountOnEnter unmountOnExit in={isOpen} direction="left">
        <Box component="aside" className={`${classes.drawer}${isFloating ? ` ${classes.floating}` : ''}`}>
          <Box className={classes.title} style={{ minHeight: titleHeight }}>
            <Typography variant="h2" component="h3">
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.subtitle}>{subtitle}</Box>
          {!isDividerHidden && <Divider />}
          <Box className={classes.drawerContent}>{children}</Box>
        </Box>
      </Slide>
    </Portal>
  );
};

const SlideOut = { Drawer, Container };
export default SlideOut;
