import React from 'react';

import { Link, useLocation, matchPath } from 'react-router-dom';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactRouterSideMenuProps } from '.';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(1)
  },
  item: {
    textDecoration: 'none',
    color: 'inherit',
    '& .MuiListItem-root': {
      position: 'relative',
      borderRadius: theme.spacing(2),
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1, 0, 0)
    }
  },
  icon: {
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    lineHeight: 0,
    backgroundColor: theme.palette.secondary.main
  },
  linked: {
    '&:not(:last-child) .MuiListItem-root::after, &:not(:first-child) .MuiListItem-root::before': {
      content: '""',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      left: theme.spacing(1.4)
    },
    '& .MuiListItem-root::after': {
      top: '100%'
    },
    '& .MuiListItem-root::before': {
      bottom: '100%'
    },
    // Make the selected link the same color as hover
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
}));

const ReactRouterSideMenu = ({ items }: ReactRouterSideMenuProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <List component="nav" className={classes.list}>
      {items.map(({ icon, text, url }) => (
        <Link key={text} to={url} className={`${classes.item} ${classes.linked}`}>
          <ListItem button selected={url === '/' ? pathname === '/' : Boolean(matchPath(pathname, { path: url }))}>
            <span className={classes.icon}>{icon}</span>
            <ListItemText primary={text} />
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default ReactRouterSideMenu;
