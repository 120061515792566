import { Switch, withStyles } from '@material-ui/core';

const Toggle = withStyles((theme) => ({
  root: {
    width: 30,
    height: 15,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.main,
    '&$checked': {
      transform: 'translateX(15px)',
      color: theme.palette.secondary.light,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

export default Toggle;
