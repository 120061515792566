import React from 'react';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SideMenuProps } from './SideMenu.proptype';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(1)
  },
  item: {
    textDecoration: 'none',
    color: 'inherit',
    '& .MuiListItem-root': {
      position: 'relative',
      borderRadius: theme.spacing(2),
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1, 0, 0)
    }
  },

  icon: {
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    lineHeight: 0,
    backgroundColor: theme.palette.action.disabled
  },

  linked: {
    '&:not(:last-child) .MuiListItem-root::after, &:not(:first-child) .MuiListItem-root::before': {
      content: '""',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderLeft: `2px solid ${theme.palette.action.disabled}`,
      left: theme.spacing(1.4)
    },
    '& .MuiListItem-root::after': {
      top: '100%'
    },
    '& .MuiListItem-root::before': {
      bottom: '100%'
    }
  }
}));

const SideMenu = ({ items }: SideMenuProps) => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.list}>
      {items.map(({ icon, text, onClick, selected }) => (
        <span key={text} onClick={onClick}>
          <ListItem button selected={selected}>
            <span className={classes.icon}>{icon}</span>
            <ListItemText primary={text} />
          </ListItem>
        </span>
      ))}
    </List>
  );
};

export default SideMenu;
