import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  container: {
    flexGrow: 1,
    overflow: 'auto'
  },
  header: {
    flexBasis: 0
  }
});

export default useStyles;
