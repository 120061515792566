import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  circle: {
    width: '0.75rem',
    height: '0.75rem',
    borderRadius: '50%',
    border: `0.1rem solid ${theme.palette.primary.main}`,
    display: 'inline-block',
    '&$filled': {
      backgroundColor: theme.palette.primary.main
    }
  },
  filled: {},
  line: {
    display: 'inline-block',
    width: '1em',
    borderTop: `0.1px solid ${theme.palette.primary.main}`,
    height: 0,
    transform: 'translateY(-0.4em)'
  }
}));
