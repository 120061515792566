import React, { useEffect, useRef } from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import { drawGeoJson } from './components/d3';
import { GeoMapProps } from './GeoMap.proptypes';

import { useDimensions } from '../../utils/useDimensions';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',

    '& .country.selected': {
      cursor: 'pointer'
    }
  }
}));

const GeoMap = (props: GeoMapProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const { width, height, wrapperRef } = useDimensions();
  const classes = useStyles();

  useEffect(() => {
    if (svgRef.current && props.data) {
      drawGeoJson(svgRef.current, width, height, props);
    }
  }, [width, height, props]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      {props.data ? <svg ref={svgRef} /> : <CircularProgress />}
    </div>
  );
};

export default GeoMap;
