import { makeStyles } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteColor {
    veryLight?: React.CSSProperties['color'];
  }
}

export const useStyles = makeStyles((theme) => ({
  heroPanel: {
    backgroundColor: theme.palette.primary.veryLight,
    minHeight: '500px',
    alignItems: 'center'
  },
  textGrid: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(5)
  },
  videoGrid: {},
  videoContainer: {
    padding: 0,
    width: '430px',
    height: '300px',
    backgroundColor: 'none'
  },
  video: {
    height: '100%',
    width: '100%'
  },
  smallHeader: {
    fontWeight: 'bold'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1)
  }
}));
